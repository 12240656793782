var Cameraland = Cameraland || {};

Cameraland.Slider = class {
    constructor(options) {
        this.initSlider = this.initSlider.bind(this);

        this.options = $j.extend({}, {
            carouselSelector: '.slider',
            showSlides: 1,
            showDots: false,
            showArrows: false,
            prevArrow: '<a href="#" class="slick-prev"><i class="ic-chevron-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="ic-chevron-right"></i></a>',
            autoplay: false,
        }, options);

        this.initSlider();
    }

    initSlider() {
        const showSlides = this.options.showSlides;
        const showDots = this.options.showDots;
        const showArrows = this.options.showArrows;
        const prevArrow = this.options.prevArrow;
        const nextArrow = this.options.nextArrow;
        const autoplay = this.options.autoplay;

        $j(this.options.carouselSelector).not('.slick-initialized').slick({
            slidesToShow: showSlides,
            infinite: true,
            lazyLoad: 'ondemand',
            dots: showDots,
            arrows: showArrows,
            prevArrow: prevArrow,
            nextArrow: nextArrow,
            autoplay: autoplay,
            speed: 1000,
            autoplaySpeed: 4000
        });
    }
};